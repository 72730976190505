import { v4 as uuidv4 } from "uuid"

// HEAD DATA
export const headData = {
  title: "mattcanavan.dev/",
  lang: "en", // e.g: en, es, fr, jp
  description: "Welcome to my website",
}

// ABOUT DATA
export const aboutData = {
  byline: "Hi, I'm Matt",
  aboutMe: "I'm passionate about Web Development and life-long learning.",
  githubURL: "https://github.com/mattcanavan",
  email: "mailto:hello@mattcanavan.dev",
}

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv4(),
    title: `"Pixel Perfect" Landing Page`,
    info: `Mock website emphasising professional design. Project design file provided by FrontendMentor.io`,
    deployedURL: `https://mattcanavan.dev/bookmark-landing-page/`, //if blank, no btn will appear
    repoURL: `https://github.com/mattcanavan/bookmark-landing-page`, //if blank, no btn will appear
    keySkills: ["Mobile-first design", "Semantic HTML", "Sass", "Flexbox"],
    img: `bookmark-landing-page.png`,
    alt: ``,
  },
  {
    id: uuidv4(),
    title: `"Pixel Perfect" Landing Page`,
    info: `Mock website emphasising professional design. Project design file provided by FrontendMentor.io`,
    deployedURL: `https://mattcanavan.dev/loopstudios-landing-page/`, //if blank, no btn will appear
    repoURL: `https://github.com/mattcanavan/loopstudios-landing-page`, //if blank, no btn will appear
    keySkills: ["Mobile-first design", "Sass", "CSS Grid"],
    img: `loopstudios-landing-page.png`,
    alt: ``,
  },
  {
    id: uuidv4(),
    title: "RESTful API using Express.js",
    info: `Served as the backend of a React App. Users were authenticated with JWT. 
    SQLite3 was used in development and PostgreSQL in product for data persistence.
    `,
    deployedURL: "", //if blank, no btn will appear
    repoURL: "https://github.com/BW-TruckTracker/BackEnd", //if blank, no btn will appear
    keySkills: ["Express.js", "Knex ORM", "Heroku", "JWT"],
    img: "backend.png",
    alt: "README from github repo",
  },
  {
    id: uuidv4(),
    title: `Lambda Alumni Network SPA`,
    info: `A virtual community for Lambda School Alumni to connect and exchange career advice, share job opportunities, and much more!`,
    deployedURL: ``, //if blank, no btn will appear
    repoURL: `https://github.com/Lambda-School-Labs/lan-fe-a`, //if blank, no btn will appear
    keySkills: [
      "Working within an inherited codebase",
      "Teamwork (group project)",
      "JavaScript (ES6, React, Redux)",
    ],
    img: `LAN-welcome1.png`,
    alt: `welcome page for lambda alumni network`,
  },
  {
    id: uuidv4(),
    title: "Portfolio Site with Gatsby",
    info: `The Gatsby framework pairs lightning fast static websites with extremely helpful plugins
    and the results speak for themselves. Not to mention it's based on React! Seriously check
    out this framework for your next project.`,
    deployedURL: "https://mattcanavan.dev", //if blank, no btn will appear
    repoURL: "https://github.com/mattcanavan/mattcanavan.github.io", //if blank, no btn will appear
    keySkills: ["Gatsby", "GraphQL", "Context API", "SCSS"],
    img: "gatsby.png",
    alt: "homepage of this very website",
  },
  {
    id: uuidv4(),
    title: `Play Baccarat`,
    info: `In this project I simulate the card game Baccarat with vanilla JS and Es6 promises to 
  produce legible code. The deck of cards was built using a class and can be reused for 
  other card games.`,
    deployedURL: `https://mattcanavan.dev/play-baccarat/`, //if blank, no btn will appear
    repoURL: `https://github.com/mattcanavan/play-baccarat`, //if blank, no btn will appear
    keySkills: ["OOP JavaScript", "CSS Grid", "Es6 Promises"],
    img: `baccarat.png`,
    alt: `photo of gameboard from website`,
  },
]

// template
// {
//   id: uuidv4(),
//   title: ``,
//   info: ``,
//   deployedURL: ``, //if blank, no btn will appear
//   repoURL: ``, //if blank, no btn will appear
//   keySkills: [``],
//   img: ``,
//   alt: ``
// },
